import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import background from "../../Figures/PRO002Banner.jpg"
import pageLayout from "../pages.module.css"
import testsetup from "../../Figures/TestSetupboerderij.jpg"
import pumpkiller from "../../Figures/pompkiller.jpg"
import Plantpart from "../../Figures/Planthouder.jpg"
import ProjectOverview from "../../components/ProjectOverviews/PRO002Overview.js"


export default function PRO002_Intro() {
  return (
      <div>
      <div> 
    <Layout>
    </Layout>
    <div style={{backgroundImage: "url(" + background + ")" ,backgroundSize: "cover", minHeight: "200px"}} >
        <div className={pageLayout.text}>
      <h1 style={{color: "white"}}>PRO002 - Hydroponic Farm</h1>
      <br />
      <h2 style={{color: "white"}}>Test setup</h2>
        </div>
      </div>
      </div>
      <div style={{}}>
      <div className={pageLayout.text}>
        <h2>Parts parts parts</h2>
        <p>Starting things simple I wanted to try the concept and get a feel for the feasibility. This setup consists of one T-junction of 10mm diameter plastic piping, normally used for plumbing.<br/>
        Holding the plant (cauliflower) is a 3D printed part, partly filled with perlite. The plant itself is seeted in a little bit of earth. I first tried to germinate the seeds in continuously moist earth, in the setup, but that was not a success. Germinating in a little more dry earth worked much better.</p> 
        <p>The figure below shows the small setup I have right now (yes I did say I wasn't going to use an Arduino, but I am still waiting on my nodemcu V2 esp8266 board). The lamp is a 25 W UV light used for terrariums and has the right spectral properties for growing plants (In the future I might move to LED's though).
          Everything is placed on a plastic container that holds the nutrient.
        <br />
        </p>
        <figure>
        <img width="40%"  src={testsetup} style={{borderRadius: 20, marginLeft: "30%" , marginRight: "30%"}} alt="Test setup hydroponics" />
        <figcaption style={{textAlign: "center"}}>Fig.1 - Single plant setup for hydroponics tryout.</figcaption>
        </figure>
        <p>Not the intended ebb and flow method either. The valve I bought needs a delta pressure of 0.8 bars so nothing dripped trough on this very small usecase. So now, the pump is activated every thirty minutes for 5 seconds.
          When moving to the esp module I want to be able to remotely change the timings for both the pump and the light. More on that next time.
        </p>
        <h2>'Hydraulics'</h2>
        <p>As mentioned before, the part I used has been 3D printed. The part has been printed in two pieces (so it is 2 parts?) in PLA. Not the ideal material, but it is what was available, we'll see how long it lasts.</p>
        <figure>
        <img width="40%"  src={Plantpart} style={{borderRadius: 20, marginLeft: "30%" , marginRight: "30%"}} alt="3D printed plant holder" />
        <figcaption style={{textAlign: "center"}}>Fig.2 - 3D printed plant holder.</figcaption>
        </figure>
        <p>I started out with a <a href="https://i.ebayimg.com/images/g/cNcAAOSwgA1b0NjZ/s-l300.png">6 V pump</a>, which could then easily be actuated from the same supply as the controller. In the top of the plant holder I placed a ring of tubing with holes to evenly distribute the nutrient arount the plant base.</p>
        <p>However, the holes were too small, or not enough of them, causing too much resistance of the pump and after three days I had to say goodbye. </p>
        <figure>
        <img width="40%"  src={pumpkiller} style={{borderRadius: 20, marginLeft: "30%" , marginRight: "30%"}} alt="Killer of pumps" />
        <figcaption style={{textAlign: "center"}}>Fig.3 - Deathring, killer of pumps.</figcaption>
        </figure>
        <p>With my sights set on the future, I removed the ring, and replaced the pump with a more powerfull <a href="https://estore.tomandco.com/nl/filters-pompen/2489-tomco-aqua-flow-600-5400116141492.html">aquarium pump</a>. This seems to be a better fit and should be able to stay with me when upgrading to a bigger 'farm'.</p>
        <p></p>
        <p> <br /> <Link to="../PRO002_intro">Previous page</Link></p>
      </div>
      <div className={pageLayout.side}>
        <h2 style={{}}>Project overview:</h2>
      <ProjectOverview></ProjectOverview>
      </div>
      </div>
    </div>
  )
}